import React from "react";
import imageOne from "../../assets/image/home-3/png/app.png";
import imageThree from "../../assets/image/home-3/png/big-green-circle-img.png";
import searchIcon from '../../assets/image/svg/searchIcon.svg';
import iphone from "../../assets/image/svg/iphone.svg";
import historyArrow from '../../assets/image/svg/historyArrow.svg';
import pdfIcon from '../../assets/image/svg/pdfIcon.svg';
import shareIcon from '../../assets/image/svg/shareIcon.svg';

const Attributes = ({ className, ...rest }) => {
  const widgetArray = [

    {
      title: "Advanced search filters",
      icon: searchIcon,
    },
    {
      title: "Share documents with your team*",
      icon: shareIcon,
    },

    {
      title:
        "Unlimited documents*",
      icon: pdfIcon,
    },
    {
      title:
        "Available on every device",
      icon: iphone,
    },
    {
      title:
        "5-year history*",
      icon: historyArrow,
    },
  ];
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-5 col-lg-5 col-xs-9"
            data-aos="fade-right"
            data-aos-delay={500}
          >
            <div className="l3-content-image-5 mt-5 mt-lg-0">
              <div className="img-1">
                <img className="w-100" src={imageOne} alt="" />
              </div>
              {/* <div className="img-2">
                <img className="w-100" src={imageTwo} alt="" />
              </div> */}
              <div className="img-3 elephant-feijoa">
                <img className="w-100" src={imageThree} alt="" />
              </div>
            </div>
          </div>
          <div className="col-xl-1 col-lg-1 col-xs-12 mt-5 mb-5"></div>
          <div
            className="col-xl-6 col-lg-6 col-md-7 col-xs-10 "
            data-aos="fade-left"
            data-aos-delay={500}
          >
            <h2 className="font-size-10 mb-8 pt-xs-6">
              Get all your documents in a single place
            </h2>

            <div className="list" data-aos="fade-right" data-aos-delay={800}>
              {widgetArray.map(({ title, icon }, index) => {
                return (
                  <div className="d-flex align-items-center mb-6" key={index}>
                    <div className="circle-59 bg-gray-3 mr-6 ">
                      <img src={icon} alt="" />
                    </div>
                    <p
                      className="font-size-8 heading-default-color font-weight-medium mb-0"
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></p>
                  </div>
                );
              })}
            </div>
            *premium plan
          </div>
        </div>
      </div>
    </div>

  );
};

export default Attributes;
