import React from "react";
import Hero from "../sections/home3/Hero";
import LawyFlow from "../sections/home3/LawyFlow";
import Attributes from "../sections/home3/Attributes";
import Pricing from "../sections/home3/Pricing";
import PageWrapper from '../components/PageWrapper/PageWrapper';

const Home3 = () => {
  return (

    <PageWrapper>
      <div>
        <Hero className="bg-default-3 pt-25 pt-lg-29" />
        <LawyFlow className="pt-xs-6 pb-lg-29 pt-lg-10 border-top border-default-color-1" />
        <Attributes className="pt-xs-8 pb-13 pb-lg-17" />
        <Pricing className="pt-13 pt-lg-25 pb-8 pb-lg-22" />
      </div>
    </PageWrapper>

  );
};
export default Home3;
